// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-become-a-coach-js": () => import("./../src/pages/become-a-coach.js" /* webpackChunkName: "component---src-pages-become-a-coach-js" */),
  "component---src-pages-calculator-index-js": () => import("./../src/pages/calculator/index.js" /* webpackChunkName: "component---src-pages-calculator-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-planner-excercises-js": () => import("./../src/pages/planner/excercises.js" /* webpackChunkName: "component---src-pages-planner-excercises-js" */),
  "component---src-pages-planner-index-js": () => import("./../src/pages/planner/index.js" /* webpackChunkName: "component---src-pages-planner-index-js" */),
  "component---src-pages-planner-programs-js": () => import("./../src/pages/planner/programs.js" /* webpackChunkName: "component---src-pages-planner-programs-js" */),
  "component---src-pages-pro-js": () => import("./../src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-upgrade-js": () => import("./../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */),
  "component---src-pages-users-js": () => import("./../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-pages-view-program-js": () => import("./../src/pages/view-program.js" /* webpackChunkName: "component---src-pages-view-program-js" */),
  "component---src-pages-welcome-js": () => import("./../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

