import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  user: {},
  token: "dummy",
  activeNavItem: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "SIGN_IN":
      if (typeof window !== `undefined`) {
        window.localStorage.setItem("authToken", action.token)
      }
      return {
        ...state,
        user: action.user,
        token: action.token,
        activeNavItem: "Programs",
      }
    case "SIGN_OUT":
      return {
        ...state,
        user: {},
        token: "",
      }
    case "SET_NAV_ITEM":
      if (action.item === "Teamgym planner") {
        return {
          ...state,
          activeNavItem: "Programs",
        }
      } else {
        return {
          ...state,
          activeNavItem: action.item,
        }
      }

    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
